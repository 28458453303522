// Client-side caching
export const cache = {
  set: (key: string, data: any, ttl = 3600) => {
    const item = {
      data,
      timestamp: Date.now(),
      ttl: ttl * 1000
    };
    localStorage.setItem(key, JSON.stringify(item));
  },

  get: (key: string) => {
    const item = localStorage.getItem(key);
    if (!item) return null;

    const { data, timestamp, ttl } = JSON.parse(item);
    if (Date.now() - timestamp > ttl) {
      localStorage.removeItem(key);
      return null;
    }

    return data;
  },

  clear: () => {
    localStorage.clear();
  }
};