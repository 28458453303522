import { seoMetadata } from '../data/seoMetadata';

export const generateSeoTags = ({
  title,
  description,
  canonical = 'https://flytti.se',
  type = 'website',
  image = 'https://flytti.se/og-image.jpg',
  publishedTime,
  modifiedTime,
  cityName,
  keywords = [],
  author = 'Flytti.se',
  category,
  price,
  availability
}: {
  title: string;
  description: string;
  canonical?: string;
  type?: string;
  image?: string;
  publishedTime?: string;
  modifiedTime?: string;
  cityName?: string;
  keywords?: string[];
  author?: string;
  category?: string;
  price?: string;
  availability?: string;
}) => {
  const fullTitle = `${title} | Flytti.se`;
  const defaultImage = 'https://flytti.se/og-image.jpg';
  
  // Enhanced schema markup with more specific types
  let schema: any = {
    "@context": "https://schema.org",
    "@type": type === 'article' ? 'Article' : 'WebPage',
    "headline": title,
    "description": description,
    "image": image || defaultImage,
    "url": canonical,
    "datePublished": publishedTime,
    "dateModified": modifiedTime || publishedTime,
    "author": {
      "@type": "Organization",
      "name": author
    },
    "publisher": {
      "@type": "Organization",
      "name": "Flytti.se",
      "logo": {
        "@type": "ImageObject",
        "url": "https://flytti.se/logo.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": canonical
    }
  };

  // Add service schema if applicable
  if (type === 'service') {
    schema = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": title,
      "description": description,
      "provider": {
        "@type": "Organization",
        "name": "Flytti.se",
        "url": "https://flytti.se",
        "logo": "https://flytti.se/logo.png"
      },
      "areaServed": {
        "@type": "Country",
        "name": "Sweden"
      },
      "category": category,
      "offers": {
        "@type": "Offer",
        "price": price || "Kontakta för pris",
        "priceCurrency": "SEK",
        "availability": availability || "https://schema.org/InStock"
      }
    };
  }

  // Add local business schema for city pages
  if (cityName) {
    schema = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": `Flyttfirma i ${cityName}`,
      "description": description,
      "url": canonical,
      "image": image || defaultImage,
      "address": {
        "@type": "PostalAddress",
        "addressLocality": cityName,
        "addressCountry": "SE"
      },
      "geo": {
        "@type": "GeoCoordinates"
        // Coordinates would be added dynamically based on city
      },
      "areaServed": {
        "@type": "City",
        "name": cityName,
        "containedInPlace": {
          "@type": "Country",
          "name": "Sweden"
        }
      }
    };
  }

  // Add FAQ schema if available
  if (type === 'faq') {
    schema["@type"] = "FAQPage";
    // FAQ items would be added dynamically
  }

  // Add breadcrumb schema
  const breadcrumb = {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": "https://flytti.se",
          "name": "Hem"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": canonical,
          "name": title
        }
      }
    ]
  };

  schema.breadcrumb = breadcrumb;

  return {
    title: fullTitle,
    meta: [
      // Enhanced Basic SEO
      { name: 'description', content: description },
      { name: 'keywords', content: keywords.join(', ') },
      { name: 'robots', content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' },
      { name: 'googlebot', content: 'index, follow, max-image-preview:large' },
      { name: 'google', content: 'notranslate' },
      { name: 'language', content: 'sv' },
      { name: 'geo.region', content: 'SE' },
      { name: 'geo.placename', content: cityName || 'Sweden' },
      { name: 'author', content: author },
      { name: 'copyright', content: `© ${new Date().getFullYear()} Flytti.se` },
      
      // Enhanced Open Graph
      { property: 'og:title', content: fullTitle },
      { property: 'og:description', content: description },
      { property: 'og:type', content: type },
      { property: 'og:image', content: image || defaultImage },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '630' },
      { property: 'og:url', content: canonical },
      { property: 'og:site_name', content: 'Flytti.se' },
      { property: 'og:locale', content: 'sv_SE' },
      { property: 'og:updated_time', content: modifiedTime || new Date().toISOString() },
      { property: 'og:see_also', content: 'https://flytti.se/artiklar' },
      { property: 'og:see_also', content: 'https://flytti.se/foretag' },
      
      // Enhanced Twitter Card
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@flyttise' },
      { name: 'twitter:creator', content: '@flyttise' },
      { name: 'twitter:title', content: fullTitle },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: image || defaultImage },
      { name: 'twitter:image:alt', content: title },
      { name: 'twitter:label1', content: 'Skriven av' },
      { name: 'twitter:data1', content: author },
      { name: 'twitter:label2', content: 'Läsning' },
      { name: 'twitter:data2', content: '3 min' },
      
      // Article specific
      publishedTime && { property: 'article:published_time', content: publishedTime },
      modifiedTime && { property: 'article:modified_time', content: modifiedTime },
      category && { property: 'article:section', content: category },
      
      // Mobile & PWA
      { name: 'format-detection', content: 'telephone=no' },
      { name: 'theme-color', content: '#EB7155' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
      { name: 'apple-mobile-web-app-title', content: 'Flytti.se' },
      { name: 'application-name', content: 'Flytti.se' },
      
      // Additional SEO
      { name: 'revisit-after', content: '7 days' },
      { name: 'rating', content: 'General' },
      { name: 'referrer', content: 'no-referrer-when-downgrade' },
      { name: 'msapplication-TileColor', content: '#EB7155' }
    ].filter(Boolean),
    link: [
      { rel: 'canonical', href: canonical },
      { rel: 'alternate', href: canonical, hrefLang: 'sv-se' },
      { rel: 'alternate', href: canonical, hrefLang: 'x-default' },
      { rel: 'author', href: '/humans.txt' },
      { rel: 'manifest', href: '/site.webmanifest' }
    ],
    schema
  };
};