import { useState, useEffect } from 'react';
import { Company, db } from '../lib/supabase';
import { cache } from '../lib/cache';

export const useCompanies = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setIsLoading(true);
        
        // Check cache first
        const cachedData = cache.get('companies');
        if (cachedData) {
          setCompanies(cachedData);
          setIsLoading(false);
          return;
        }

        const { data, error } = await db.companies.getAll();
        
        if (error) throw error;
        
        // Store in cache for 1 hour
        if (data) {
          cache.set('companies', data, 3600);
          setCompanies(data);
        }
        
        setError(null);
      } catch (err) {
        console.error('Error fetching companies:', err);
        setError('Failed to load companies');
        setCompanies([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  return { companies, isLoading, error };
};